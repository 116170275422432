export function resourceHints(window, requirejs, carmiDebug, experiment) {
    const {document, rendererModel, serviceTopology} = window
    const {head} = document

    const PRECONNECTS = {
        'Wix Blog': {},
        'Wix Forum': {},
        'Wix Bookings': {},
        'Wix Video': {url: serviceTopology.basePublicUrl},
        'Wix Restaurants Menus': {},
        'Wix Get Subscribers': {widgetId: '1375babd-6f2b-87ed-ff19-5778602c8b86'},
        'Instagram Feed': {widgetId: '14635280-8c8d-5346-b643-691f84f48973'},
        'Wix Events': {widgetId: '1440e92d-47d8-69be-ade7-e6de40127106'},
        'Wix FAQ': {widgetId: '14c92de1-0e02-cbe5-98e9-c3de44d63a55'}
    }

    function resourceHint(rel, url, {as, crossorigin} = {}) {
        const link = document.createElement('link')
        link.setAttribute('rel', rel)
        link.setAttribute('href', url)
        if (as) {
            link.setAttribute('as', as)
        }
        if (crossorigin) {
            link.setAttribute('crossorigin', crossorigin)
        }
        head.appendChild(link)
    }

    function preload(url) {
        resourceHint('preload', url, {as: 'script', crossorigin: 'anonymous'})
    }

    function preconnectApp(spec) {
        const preconnect = PRECONNECTS[spec.appDefinitionName]
        if (preconnect) {
            let {url} = preconnect
            if (!url) {
                const {widgetId} = preconnect
                if (widgetId && spec.widgets) {
                    const widget = spec.widgets[widgetId]
                    if (widget) {
                        url = widget.widgetUrl || widget.mobileUrl
                    }
                }
                if (!url) {
                    url = spec.sectionUrl || spec.sectionMobileUrl
                    if (!url && spec.appWorkerUrl) {
                        url = spec.appWorkerUrl.replace(/[^/]+$/, '')
                    }
                }
            }
            if (url) {
                resourceHint('preconnect', url)
            }
        }
    }

    if (experiment.isOpen('preloadBoltScript')) {
        const {src} = document.currentScript
        if (src) {
            const chunkIds = carmiDebug ? [0, 1] : [1, 2]
            chunkIds.map(i => src.replace(/main-r\.min/, `bolt-main-r.${i}`)).forEach(preload)
        }
        const boltMain = `${requirejs.toUrl('bolt-main')}.js`
        preload(boltMain)
    }

    if (experiment.isOpen('preconnectApps') && rendererModel) {
        const {clientSpecMap} = rendererModel
        if (clientSpecMap) {
            const specs = Object.values(clientSpecMap)
            specs.forEach(preconnectApp)
        }
    }
}
